import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/psoriazal-plus-kak-da-se-spravim-header.jpg"
import LuteinComplexTablet from "./../../images/psoriazal-plus-kak-da-se-spravim-header.jpg"
import LuteinComplexDesktop from "./../../images/psoriazal-plus-kak-da-se-spravim-header.jpg"
import LuteinComplexXL from "./../../images/psoriazal-plus-kak-da-se-spravim-header.jpg"

import Sarsaparila from "./../../images/psoriazal-plus-kak-da-se-spravim-inside.jpg"

const PsoriazalSpravim = () => (
  <Layout backButton={true} pageInfo={{ pageName: "psoriazal-spravim" }}>
    <Seo title="Как да се справим с псориазиса?" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              КАК ДА СЕ СПРАВИМ С ПСОРИАЗИСА?
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Как да се справим с псориазиса?"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Как да се справим с псориазиса?
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <p>
            Псориазисът е кожно заболяване, чието наименование произхожда от
            гръцката дума "псора", означаваща лющене. Заболяването може да се
            появи във всяка възраст, но най-често се манифестира между 20 и 40
            години като двата пола се засягат еднакво. В България от псориазис
            боледуват 1-2% от населението. Причината за тази често срещана
            болест все още не е напълно известна. До голяма степен значение има
            наследствеността (според статистиката в около 60% от случаите), но
            много повече се унаследява една скрита готовност на кожата да
            реагира с псориатичен обрив, отколкото самото заболяване. Най-често
            като провокиращи причини се посочват различни инфекциозни
            заболявания (грип, ангини и др.), травми, психични травми,
            по-продължително излагане на слънце при бедни на пигмент хора, прием
            на различни лекарства (антибиотици, бетаблокери и др.) и т.н.
          </p>
          <p>
            Първоначално болестта се проявява с червени на цвят и кръгли по
            форма петна по кожата, които постепенно се покриват с множество
            сребристо-бели люспи. По-късно петната се уплътняват, надигат се над
            кожата и се разрастват периферно. Тези болестни промени най-често се
            локализират върху кожните участъци, където лесно се получават
            микротравми поради разтягане - по лактите и колената. Разбира се,
            могат да бъдат обхванати и по-големи площи от тялото като за
            крайниците е характерно, че се засягат предимно разгъвните
            повърхности. Често псориазисът ангажира както кожата на главата (в
            косата се наблюдава изобилие от сребристо-бели люспи, наподобяващи
            на пърхот), така и ноктите, върху които се виждат точковидни
            вдлъбвания или жълтеникави петънца. В по-тежките случаи заболяването
            може да се усложни и поради засягане на ставите. Вариантите на
            протичане са многобройни, но по принцип преобладават
            хронично-рецидивиращите форми. Индивидуалните отклонения обаче могат
            силно да се различават както по честотата на рецидивите (само един
            пристъп през целия живот, два-три пристъпа, непрекъснато повтарящи
            се рецидиви), така и по времетраенето на светлите интервали -
            наблюдават се светли периоди от седмици, но и от години и от
            десетилетия. След продължаващ с месеци генерализиран пристъп може да
            настъпи подобрение, свеждащо се до частично остатъчно засягане на
            лактите и коленете, а може да настъпи и пълно изчистване. Трябва да
            се има предвид, че понякога по време на появата на пресен обрив може
            да възникне и сърбеж, но преди всичко псориазисът е несърбяща
            дерматоза.
          </p>
          <p>
            Лечението на псориазиса невинаги е лесно. Употребяват се различни
            средства, преобладаващата част от които са предназначени за мазане
            на кожата, а други за приемане през устата.
          </p>
          <p>
            За директно нанасяне върху кожата се използват: Кремове, унгвенти,
            разтвори и други, които съдържат кортикостероиди (бетноват,
            дипрозон, дипросалик, синалар, флуцинар, дерматоп, елоком, лоринден
            и др.). Те са ефикасни, лесно се прилагат и са приемливи от
            козметична гледна точка. За съжаление, след прекратяване на
            лечението, псориазисът твърде скоро се изявява отново и то в много
            по-тежка форма, отколкото преди лечението. Тези лекарства изтъняват
            кожата и е възможно да провокират образуването на стрии. Освен това,
            при дълготрайна употреба върху кожата на лицето се появява засилено
            окосмяване, което е абсолютно неприемливо, особено за жените.
          </p>
          <p>
            Дайвонекс под формата на унгвент, който е сроден с витамин D. Тъй
            като не съдържа кортикостероиди, той може да се прилага
            продължително, но при условие, че използваното количество унгвент не
            надвишава 100 грама седмично.
          </p>
          <p>
            Каменовъглен катран. У нас такова лекарство е псанолът, който е
            много ефективен за лечението на псориазис. Недостатъци - зацапва
            кожата (трудно се отмива) и дрехите, мирише неприятно, прави кожата
            по-чувствителна към светлината и играе ролята на канцероген.
          </p>
          <p>
            Дитранолът (синтетичен химикал) е може би най-ефикасното средство за
            локална терапия на псориазиса, обаче замърсява кожата, постелното
            бельо и дрехите. Освен това може да дразни кожата, при невнимателно
            прилагане може направо да я изгори, особено в слабините и под
            мишниците, където тя е много деликатна.
          </p>
          <p>
            За приемане през устата се използват: Неотигазон производно на
            витамин А. Най-удачно е да се използва при засягане на обширни
            участъци от кожата, особено когато локалната терапия не е ефективна.
          </p>
          <p>
            Недостатъци - постепенно се развива чувство за сухота в очите и
            устата. Повишава се нивото на холестерина и другите масти в кръвта.
            Възможно е да увреди половите клетки (яйцеклетки и сперматозоиди),
            поради което се препоръчва отлагане на забременяването до 2 години
            след спиране на лечението.
          </p>
          <p>
            Циклоспорин ефикасен е, но е опасен, защото може да повишава
            кръвното налягане, нарушава функцията на бъбреците и засилва
            окосмяването.
          </p>
          <p>
            Метотрексатът има мощно действие, но може да увреди костния мозък и
            черния дроб.
          </p>
          <p>
            Кортикостероидни таблетки - не бива да се използват. Състоянието
            бързо се подобрява, но след спирането им псориазисът обикновено се
            възвръща в много тежка форма.
          </p>
          <p>
            В последно време голяма популярност придобиха имуномодулиращите
            средства за прием през устата или за директна апликация върху кожата
            - посрещат се с голям ентусиазъм, но някои от тях още не са
            регистрирани в България, а други са толкова скъпи, че са достъпни
            само за ограничен кръг от пациенти.
          </p>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Без да претендираме за абсолютна изчерпателност, от така направения
            преглед на медикаментите за лечение на псориазис е очевидно, че те
            притежават както положителни качества, така и някои нежелани ефекти.
            Това ограничава възможностите за продължително приложение на някои
            от тях, а други ги прави напълно неизползваеми при определени
            условия (така например, ако страдащият от псориазис се разболее от
            хепатит, той не може да се лекува с метотрексат). Поради това
            заслужен интерес предизвикват всички препарати, характеризиращи се с
            нисък рисков потенциал при дълготрайна употреба. Известно е, че
            такива са преобладаващата част от фитотерапевтичните средства,
            особено притежаващите многокомпонентни рецептури. Това позволява в
            тях да се постигне не само максимално взаимодействие между
            активнодействащите съставки на различните билки, но и избирателно да
            се стимулира ефектът на някои от растителните субстанции.
            Понастоящем билковите продукти се предлагат под формата на таблетки,
            капсули, сиропи, кремове, шампоани, балсами, включително и паста за
            зъби. За постигане на добър ефект е необходимо продължително
            приложение, което обикновено трябва да надхвърля 2-3 месеца. Този
            подход е напълно приемлив, тъй като продуктите са безвредни, нямат
            нежелателни странични ефекти и са достъпни от финансова гледна
            точка.
          </p>
          <p>
            В тази сфера на разсъждение е редно да припомним, че България е
            световно призната като една от най-интересните страни в Европа по
            отношение на флората. Tова е така, защото върху сравнително малката
            ни територия виреят над 3200 растителни вида. Неоценимо богатства са
            и многобройните видове билки, над 150 от които се използват във
            фармацевтичната промишленост. Родината ни е известна и като "страна
            на билките". Тази слава се засилва с основание и от факта, че у нас
            растат редки, срещани само тук, лечебни растения. Повечето от тях
            имат названия, свързани с планината, в която се срещат. Такива са
            родопският силивряк (Haberlea rhodopensis), родопското лале (Tulipa
            rhodopea) и др.
          </p>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Поради това българската история на билколечението е много древна.
            Изключителните постижения на нашите фитотерапевти са всепризнати.
            Така например, лечението на Паркинсоновата болест с лудо биле,
            въведена от народния лечител Иван Раев, е известно като "българско
            лечение" (cura bulgara). Академик Пасков даде на света и на болните
            от детски паралич лекарството нивалин (Nivalin), извлечено от нашето
            бяло кокиче. Понастоящем все повече лекарствени растения се
            утвърждават като ефективни средства за лечение и на различни кожни
            заболявания. Особено подходящи за фитотерапия са хронично
            протичащите дерматози, при които е необходимо комплексно третиране,
            а също така и когато резултатите от класическите медикаменти са
            непълни и нетрайни. Важно е да се има предвид, че билковото лечение
            се съпровожда от психостимулиращи импулси, ускоряващи оздравителните
            процеси. Поради това то намира приложение при много болести на
            кожата - дерматити от различен характер, акне, косопад, разширени
            вени, екзема, неестетични тъмни петна (лунички и други
            хиперпигментации), хемороиди, циреи, херпеси, невродермит и др.
            Изключително подходящ за фитотерапевтично третиране е псориазисът,
            особено неговите хронично протичащи форми. Един от най-изявените
            класици на българската дерматологична школа - професор П.
            Попхристов, препоръчва лечението на псориазиса да се провежда с
            билката сарсапарила. Лично той я прилага така: 20 грама корени от
            сарсапарила (radix sarsaparilla) се накисват вечер в 1 литър вода.
            На сутринта се изварява 15-20 минути. След това една част се изпива
            гореща, а останалата част от отварата се доизпива през деня. Това
            лечение се прилага 20 дни. От radix sarsaparilla има и готови
            препарати - тинктури и екстракти. Перорално лечение с това растение
            се препоръчва и под следната форма: една супена лъжица счукани
            корени се вари в половин литър вода в продължение на 10 минути и
            след изстиване се прецежда. Приема се по 1 винена чаша 3 пъти на
            ден. Ефективността на екстракта от сарсапарила се дължи на
            съдържащите се в нея голям брой биоактивни вещества като сапонини,
            стероиди и минерали, които подкрепят очистителните функции на
            организма, стимулирайки отделянето на вредните вещества от него.
            Сарсапарилата притежава и силно изявено противоалергично, диуретично
            и хормоноактивиращо действие. Поради това освен при псориазис тя се
            прилага и при екземи, уртикария и косопад. Използва се също като
            имуностимулатор с общ тонизиращ ефект.
          </p>
        </Col>
      </Row>
      <Row className="product-container">
        <Col sm={7}>
          <p>
            Разчитайки именно на тези качества на сарсапарилата, екип от
            специалисти към българската фирма "ВИТА ХЕРБ" създаде на нейна
            основа препарата "Псориазал плюс", в който обаче включи и екстракти
            от бреза, репей, коприва и камшик.
          </p>
          <p>
            Екстрактът от листата на бреза съдържа около 3% сапонини,
            спиракозид, гликозиди, смоли, 9% дъбилни вещества от
            пирокатехиновата група, 9% захар, инозид, горчиви вещества, около
            0,05% етерично масло, жълти багрилни вещества, витамин С, провитамин
            А и др, на които се дължат силно изявените му дерматотонично,
            отводняващо и противомикробно действие.
          </p>
        </Col>
        <Col sm={5}>
          <Image className="w-100 float-right" src={Sarsaparila} />
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Екстрактът от репей е богат на слузни, дъбилни, горчиви и смолисти
            вещества, алмитинова и стеаринова киселина, сулфати и фосфати на
            калция, калия и магнезия и в по-големи количества протеини (около
            12%) и полизахариди, особено инулин (до 45%).
          </p>
          <p>
            Корените от репей ускоряват растежа на нови клетки в организма, в
            това число и на здрави кожни клетки. Това им действие се дължи
            основно на съдържащия се в тях инулин. Освен това те действат
            дерматотонично, противоалергично, противомикробно, антисептично и
            епителизиращо.
          </p>
          <p>
            Екстрактът от коприва включва дъбилни и белтъчни вещества,
            каротиноиди, хлорофил (около 3%), различни киселини (мравчена,
            аскорбинова, пантотенова) и витамините К и В2. Поради това той
            упражнява дерматотоничен, противоалергичен, успокояващ,
            противосърбежен и антисклеротичен ефект.
          </p>
          <p>
            Екстрактът от камшик съдържа 0,2% етерично масло, дъбилни вещества
            (катехини), флавоноиди, силициева киселина (около 12%), витамините Р
            (никотинамид) и С, органични киселини и следи от манган, което му
            придава противовъзпалителни и противовирусни свойства.
          </p>
          <p>
            От всичко казано дотук се вижда, че "Псориазал плюс" е натурален
            продукт, който действа като помощно средство срещу възпалителните
            процеси в кожата и, подпомагайки нейното кръвоснабдяване,
            благоприятства за нормализиране на състоянието й. Благодарение на
            това прилагането на този препарат при различните форми на псориазис
            води до редукция на обривите и облекчаване на субективните
            оплаквания.
          </p>
          <p>
            Таблетките “Псориазал плюс” не предизвикват дразнения и
            свръхчувствителност и могат да се прилагат дълго време, включително
            с предписани от лекуващия лекар медикаменти.
          </p>
          <p>
            <i>Д-р Николай Подолешев, Специалист дерматолог</i>
          </p>
          <p>
            При написването на статията са използвани голям брой български и
            чуждестранни литературни източници.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>Можете да откриете ПСОРИАЗАЛ ПЛЮС в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/psoriazal-plus">
              Повече информация за ПСОРИАЗАЛ ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PsoriazalSpravim
